<template>
  <slide-over-right
    :id="sorId"
    editType="marketplace"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center  panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <div class="flex items-center pt-8">
            <ValidationProvider vid="billing_category" name="" rules="required">
              <div class="flex">
                <label class="flex items-center cursor-pointer">
                  <input
                    v-model="form.billing_category"
                    type="radio"
                    class="form-radio"
                    name="Billing"
                    value="company"
                  />
                  <span class="ml-2 text-sm">Company</span>
                </label>

                <label class="flex items-center mt-px ml-4 cursor-pointer">
                  <input
                    v-model="form.billing_category"
                    type="radio"
                    class="p-1 form-radio"
                    name="Billing"
                    value="individual"
                  />
                  <span class="ml-2 text-sm">Individual</span>
                </label>
              </div>
            </ValidationProvider>
          </div>
          <div
            v-if="form.billing_category === 'company'"
            class="flex items-center pt-4"
          >
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Company Name"
                placeholder="e.g. DUK"
                v-model="form.company_name"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                type="email"
                rules="required"
                label="Company Email"
                placeholder="e.g. example@company.com"
                v-model="form.company_email"
              />
            </div>
          </div>

          <div
            v-if="form.billing_category === 'individual'"
            class="flex items-center pt-4"
          >
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Name"
                placeholder="e.g. Joe"
                v-model="form.legal_name"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                type="email"
                rules="required"
                label="Email"
                placeholder="e.g. joe@example.com"
                v-model="form.email"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.country.id"
                type="richselect"
                name="Country"
                label="Country"
                rules="required"
                placeholder="e.g. Finland"
                value-attribute="id"
                text-attribute="name"
                :options="countries"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <!-- <AppInput
                v-if="!getCityDisabledState"
                v-model="form.city"
                type="richselect"
                name="city"
                label="City"
                rules="required"
                placeholder="Select Country"
                value-attribute="id"
                text-attribute="name"
                :options="cities"
              /> -->
              <text-input
                rules="required"
                label="City"
                placeholder="e.g. Helsinki"
                v-model="form.city"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                label="State"
                placeholder="e.g. Eastern Finland"
                v-model="form.state"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Zip"
                placeholder=""
                v-model="form.zip_code"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="VAT ID"
                placeholder=""
                v-model="form.vat_id_number"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.address"
                type="textarea"
                name="address"
                label="Address"
                rules="required"
                placeholder=""
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3 my-btn "
          >Save</t-button
        >
      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { MarketplaceBuyerBillingInfoConfig } from '@/config/MarketplaceBuyerBillingInfoConfig'
import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
import TextInput from '@/components/form/TextInput'
// import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
export default {
  name: 'AddEditMarketplaceBillingInfo',
  components: {
    SlideOverRight,
    TextInput,
    // InputLabel,
    AnchorButton,
    SlideOverRightTrigger,
  },
  data() {
    return {
      sorId: MarketplaceBuyerBillingInfoConfig.events.sorId,
      isLoading: false,
      form: {
        billing_category: null,
        company_name: null,
        company_email: null,
        legal_name: null,
        email: null,
        country: {
          id: '',
        },
        address: null,
        city: null,
        state: null,
        zip_code: null,
        vat_id_number: null,
        buyer: null,
      },
      itemId: '',
      countries: [],
      cities: [],
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return this.itemId.length > 1
    },
    titleText() {
      return this.isEditing
        ? 'Edit Billing Information'
        : 'Add Billing Information'
    },

    getCityDisabledState() {
      if (!this.form.country.id) return true
      if (this.cityIsLoading) return true
      return false
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        console.log(updatedData)
      },
    },
  },
  mounted() {
    EventBus.$on(
      MarketplaceBuyerBillingInfoConfig.events.editingData,
      (data) => {
        this.init()
        console.log('market-ae', data)
        this.itemId = data.buyerBillingInfoDetailsId
        this.countries = data.countries
        if (this.itemId) {
          console.log('MarketplaceBuyerBillingInfoConfig', this.itemId)
          this.isLoading = true
          this.$http
            .get(MarketplaceBuyerBillingInfoConfig.api.index())
            .then((res) => {
              console.log('MarketplaceBuyerBillingInfoConfig', res.data.data[0])
              this.isLoading = false
              const resData = res.data.data[0]

              this.form.billing_category = resData.billing_category
              this.form.company_name = resData.company_name
              this.form.company_email = resData.company_email
              this.form.legal_name = resData.legal_name
              this.form.email = resData.email
              this.form.country.id = resData.country.id
              this.form.address = resData.address
              this.form.city = resData.city
              this.form.state = resData.state
              this.form.zip_code = resData.zip_code
              this.form.vat_id_number = resData.vat_id_number
              this.form.buyer = resData.buyer
            })
            .catch((e) => console.log(e))
          //edit
        }
      }
    )
  },
  methods: {
    showModal() {
      dispatchEvent(
        new Event(MarketplaceBuyerBillingInfoConfig.events.sorToggle)
      )
    },
    init() {
      this.form.billing_category = 'company'
      this.form.company_name = null
      this.form.company_email = null
      this.form.legal_name = null
      this.form.email = null
      this.form.country.id = null
      this.form.address = null
      this.form.city = null
      this.form.state = null
      this.form.zip_code = null
      this.form.vat_id_number = null
      this.form.buyer = this.$store.getters['auth/userInfo'].id
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? MarketplaceBuyerBillingInfoConfig.api.update(this.itemId)
        : MarketplaceBuyerBillingInfoConfig.api.create()

      const formProxy = {
        ...this.form,
        country: this.form.country.id,
      }

      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })

      let data = new xMan(formProxy).toFormData()
      let message = this.isEditing
        ? 'Marketplace updated successfully'
        : 'Marketplace added successfully'

      this.isLoading = true

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(
          new Event(MarketplaceBuyerBillingInfoConfig.events.sorToggle)
        )
        dispatchEvent(
          new Event(MarketplaceBuyerBillingInfoConfig.events.refresh)
        )
        this.$emit('refresh')
        // Reset the form
        this.init()
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          },
          7000
        )

        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}

.my-btn {
  background-color: var(--brand-primary-color);
}
.my-btn:hover {
  background-color: var(--brand-primary-color);
  color: white;
  opacity: 0.75;
  border-color: var(--brand-primary-color);
}
</style>
