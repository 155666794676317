<template lang="">
  <div>
    <AddEditMarketplaceBillingInfo @refresh="fetchData()" />

    <!-- billing-start -->
    <h2 class="font-bold text-2xl mt-10 mb-5">
      Card Information
    </h2>
    <div
      class="relative flex justify-between border border-gray-200 rounded-md p-2"
      :class="isLoading ? 'bill-box ' : ''"
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        class="bg-gray-100"
      />
      <div class="w-10/12 ">
        <div v-if="haveAnyBillingAddressCreated">
          <template v-for="(item, index) in buyerBillingInfoDetails">
            <div :key="`billinginfo-${index}`">
              <p>
                Name:
                {{
                  item.billing_category === 'company'
                    ? item.company_name
                    : item.legal_name
                }}
              </p>
              <p>
                Email:{{
                  item.billing_category === 'company'
                    ? item.company_email
                    : item.email
                }}
              </p>
              <p v-if="item.city">City:{{ item.city }}</p>
              <p v-if="item.zip_code">Zip Code:{{ item.zip_code }}</p>
              <p v-if="item.country">Country:{{ item.country.name }}</p>
              <p v-if="item.state">State:{{ item.state }}</p>
              <p v-if="item.vat_id_number">VAT Id:{{ item.vat_id_number }}</p>

              <p v-if="item.address">Address:{{ item.address }}</p>
            </div>
          </template>
        </div>
        <p v-else class="text-gray-500">
          Please add billing information.
        </p>
      </div>
      <div class="2/12">
        <span
          class="text-gray-600 cursor-pointer"
          @click="
            haveAnyBillingAddressCreated ? editBillingInfo() : addBillingInfo()
          "
          ><u>{{ haveAnyBillingAddressCreated ? 'Edit' : 'Add' }}</u></span
        >
      </div>
    </div>
    <!-- billing-end -->
  </div>
</template>
<script>
import { MarketplaceBuyerBillingInfoConfig } from '@/config/MarketplaceBuyerBillingInfoConfig'
import { EventBus } from '@/utils'
import AddEditMarketplaceBillingInfo from '@/views/marketplace-buyer/AddEditMarketplaceBillingInfo.vue'
import { useEndpoints } from '@/composables'
export default {
  name: 'BillingSettings',
  components: {
    AddEditMarketplaceBillingInfo,
  },
  data: () => ({
    isLoading: false,
    buyerBillingInfoDetails: [],
  }),
  async created() {
    this.fetchData()
    localStorage.removeItem('route-path')
  },
  computed: {
    haveAnyBillingAddressCreated() {
      return this.buyerBillingInfoDetails.length > 0
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const countriesReq = this.$http.get(
        useEndpoints.marketplaceBuyer.countries()
      )

      const buyerBillingInfoReq = this.$http.get(
        MarketplaceBuyerBillingInfoConfig.api.index()
      )

      const requests = [countriesReq, buyerBillingInfoReq]

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [countriesRes, buyerBillingInfoRes] = responses

            this.countries = countriesRes.data.data

            this.buyerBillingInfoDetails = buyerBillingInfoRes.data.data
          })
        )
        .catch((err) => {
          console.warn({ err })
        })
        .finally(() => (this.isLoading = false))
    },
    addBillingInfo() {
      console.log('bill-a')
      EventBus.$emit(MarketplaceBuyerBillingInfoConfig.events.editingData, {
        countries: this.countries,
        buyerBillingInfoDetailsId: '',
      })
      dispatchEvent(
        new Event(MarketplaceBuyerBillingInfoConfig.events.sorToggle)
      )
    },
    editBillingInfo() {
      console.log('bill-e')
      EventBus.$emit(MarketplaceBuyerBillingInfoConfig.events.editingData, {
        countries: this.countries,
        buyerBillingInfoDetailsId: this.buyerBillingInfoDetails[0].id,
      })
      dispatchEvent(
        new Event(MarketplaceBuyerBillingInfoConfig.events.sorToggle)
      )
    },
  },
}
</script>
<style lang="scss">
.bill-box {
  min-height: 96px;
}
</style>
