export const MarketplaceBuyerBillingInfoConfig = {
  api: {
    // get
    index: () => '/dashboard/marketplace/buyer/billing/',
    // post
    create: () => '/dashboard/marketplace/buyer/billing/',
    // patch
    update: (billingId) => `/dashboard/marketplace/buyer/billing/${billingId}/`,
    //post
    addPaymentMethod: () => '/dashboard/marketplace/buyer/payment-method/add/',
  },
  events: {
    name: 'marketplace-buyer-billinginfo',
    // refresh-index-data
    refresh: `rid-marketplace-buyer-billinginfo`,
    // slideover-right
    sorId: 'marketplace-buyer-billinginfo',
    sorOpen: 'sor-open-marketplace-buyer-billinginfo',
    sorClose: 'sor-close-marketplace-buyer-billinginfo',
    sorToggle: 'sor-toggle-marketplace-buyer-billinginfo',
    // editing-data
    editingData: 'edit-marketplace-buyer-billinginfo-data',
    // viewing-data
    viewingData: 'view-marketplace-buyer-billinginfo-data',
  },
}
