<template>
  <div>
    <h2 class="font-bold text-2xl mt-10 mb-5 flex justify-between items-center">
      Bank Information
      <p class="text-gray-500 cursor-pointer" @click="editInfo()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </p>
    </h2>
    <div class="relative rounded-md p-2">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        class="bg-gray-100"
      />
      <div v-if="!isEdit">
        <div>
          <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
            <loading :active.sync="isLoading" :is-full-page="false" />
            <form @submit.prevent="handleSubmit(addBankInfo)">
              <div>
                <div class="md:flex ">
                  <label class="label">Account Holder Name</label>
                  <div class="md:ml-20 ml-0 md:w-1/2 w-full">
                    <AppInput
                      v-model="bankDetails.account_holder_name"
                      type="text"
                      name="Account Holder Name"
                      placeholder="e.g. Joe Dark"
                      rules="required"
                      :disabled="true"
                      class=""
                    />
                  </div>
                </div>
                <div class="md:flex ">
                  <label class="label">Bank Account/ IBAN No</label>
                  <div class="md:ml-16 ml-0 md:w-1/2 w-full">
                    <AppInput
                      v-model="bankDetails.bank_account_no"
                      type="text"
                      name="Bank Account/ IBAN No"
                      placeholder="e.g.00012345"
                      rules="required"
                      :disabled="true"
                      class=""
                    />
                  </div>
                </div>
                <div class="md:flex ">
                  <label class="label">Swift Code</label>
                  <div class="md:ml-40 ml-0 md:w-1/2 w-full">
                    <AppInput
                      v-model="bankDetails.routing_number"
                      type="text"
                      name="Swift Code"
                      placeholder="e.g.040605"
                      rules="required"
                      :disabled="true"
                      class=""
                    />
                  </div>
                </div>
              </div>
              <button type="submit" ref="submitButton" class="hidden">
                Save
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div v-if="isEdit">
        <div>
          <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
            <loading :active.sync="isLoading" :is-full-page="false" />
            <form @submit.prevent="handleSubmit(addBankInfo)">
              <div>
                <div class="md:flex">
                  <label class="label">Account Holder Name</label>
                  <div class="md:ml-20 ml-0 md:w-1/2 w-full">
                    <AppInput
                      v-model="bankModel.account_holder_name"
                      type="text"
                      name="Account Holder Name"
                      placeholder="e.g. Joe Dark"
                      rules="required"
                      class=""
                    />
                  </div>
                </div>
                <div class="md:flex ">
                  <label class="label">Bank Account/ IBAN No</label>
                  <div class="md:ml-16 ml-0 md:w-1/2 w-full">
                    <AppInput
                      v-model="bankModel.bank_account_no"
                      type="text"
                      name="Bank Account/ IBAN No"
                      placeholder="e.g.00012345"
                      rules="required"
                      class=""
                    />
                  </div>
                </div>
                <div class="md:flex ">
                  <label class="label">Swift Code</label>
                  <div class="md:ml-40 ml-0 md:w-1/2 w-full">
                    <AppInput
                      v-model="bankModel.routing_number"
                      type="text"
                      name="Swift Code"
                      placeholder="e.g.040605"
                      rules="required"
                      class=""
                    />
                  </div>
                </div>
              </div>
              <section
                v-if="showButtons"
                class="flex items-center justify-end  space-x-5 "
              >
                <AppButton
                  :variant="`secondary`"
                  :class="`w-1/2`"
                  @click="cancel()"
                >
                  Cancel
                </AppButton>
                <AppButton
                  :variant="`custom`"
                  :class="`w-1/2`"
                  :isLoading="isChangeLoading"
                  @click="$refs.submitButton.click()"
                  :disabled="isFormInvalid()"
                  :style="buttonStyle"
                >
                  {{ bankDetails.bank_account_no ? 'Update' : 'Save' }}
                </AppButton>
              </section>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE, POSITION } from 'vue-toastification'
import { useEndpoints } from '@/composables'
import { xMan } from '@/utils'
import { BRAND_CONFIG } from '@/config/brand.config'

export default {
  name: 'BankSettings',
  data() {
    return {
      isLoading: false,
      isChangeLoading: false,
      isEdit: false,
      bankDetails: {},
      bankModel: {
        bank_account_no: null,
        account_holder_name: null,
        routing_number: null,
      },
      showButtons: false, // Added to control the visibility of save and cancel buttons
    }
  },
  async created() {
    this.fetchData()
  },
  watch: {
    bankDetails: {
      immediate: true,
      deep: false,
      handler(v) {
        console.log('bank-f', v)
        if (this.bankDetails.bank_account_no) {
          this.isEdit = false
        } else {
          this.isEdit = true
        }
      },
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      this.$http
        .get(useEndpoints.marketplaceBuyer.getBankInfo())
        .then((res) => {
          this.bankDetails = res.data.data
          this.bankModel.bank_account_no = this.bankDetails.bank_account_no
          this.bankModel.account_holder_name = this.bankDetails.account_holder_name
          this.bankModel.routing_number = this.bankDetails.routing_number
        })
        .catch((err) => {
          console.log({ err })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async addBankInfo() {
      this.isChangeLoading = true
      const url = useEndpoints.marketplaceBuyer.updateBankInfo(
        this.bankDetails.id
      )
      const data = new xMan(this.bankModel).toFormData()
      await this.$http
        .patch(url, data)
        .then(() => {
          this.isChangeLoading = false
          this.$toast('Added Successfully', {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.SUCCESS,
          })
          this.fetchData()
          this.cancel()
        })
        .catch((err) => {
          console.log('bank-err', { err })
          this.$toast(err.response.data.message, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
        .finally(() => (this.isChangeLoading = false))
    },
    editInfo() {
      this.isEdit = true
      this.showButtons = true // Show the save and cancel buttons when edit is clicked
    },
    cancel() {
      if (this.bankDetails.bank_account_no) {
        this.isEdit = false
        this.showButtons = false // Hide the save and cancel buttons when canceling edit
      } else {
        this.isEdit = false
      }
    },
    isFormInvalid() {
      if (this.isEdit) {
        return (
          !this.bankModel.account_holder_name ||
          !this.bankModel.bank_account_no ||
          !this.bankModel.routing_number || // Check if routing number is empty
          this.bankModel.routing_number.trim() === '' // Check if routing number is empty or only contains whitespace
        )
      }
      return false // If not in edit mode, the form is considered valid
    },
  },
  computed: {
    buttonStyle() {
      // Define the style object
      const style = {
        backgroundColor: this.isFormInvalid()
          ? '#CCCCCC'
          : BRAND_CONFIG.COLOR.PRIMARY_COLOR, // Gray when disabled, red when enabled
        color: '#FFFFFF', // White text
        border: this.isFormInvalid() ? 'none' : 'none',
        // Add other styles as needed
      }
      return style
    },
  },
}
</script>
<style lang="scss">
.input-box {
  width: 120px;
}
</style>
